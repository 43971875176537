.header {
  width: 100%;
  height: 70px;
  background-color: rgb(38, 233, 200);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header > button {
  margin-left: 3vmax;
  width: 8vmax;
  height: 3vmax;
  border-radius: 10px;
  border: none;
  background-color: rgb(241, 214, 92);
  color: white;
  font-size: 1.2vmax;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s;
}
.header > button:hover {
  background-color: rgb(236, 91, 193);
  font-size: 1.4vmax;
}

.header > button:active {
  background-color: brown;
}

@media screen and (max-width: 1200px) {
  .header > button {
    width: 10vmax;
    height: 3.3vmax;
    font-size: 1.8vmax;
  }
}
