.welcome_container {
  background-color: blanchedalmond;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.welcome_content {
  background-color: rgb(57, 77, 77);
  width: 30vmax;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome_content > h2 {
  color: white;
}
